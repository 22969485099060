<div
  class="paginator grid grid-flow-col gap-3 items-center"
  data-cy="paginator"
>
  <div
    class="icon-box cursor-pointer"
    [ngClass]="{ disabled: activePageIndex === 0 }"
    (click)="onActivatePage(activePageIndex - 1)"
  >
    <atom-icon icon-id="atom:core:arrow_back"></atom-icon>
  </div>

  <atom-dropdown
    [placeholder]="paginatorPlaceholder"
    size="small"
    atomsControl
    [(ngModel)]="activePageIndex"
    (ngModelChange)="onDropdownChange()"
  >
    <atom-options slot="input">
      @for (
        pageNumber of [].constructor(numberOfPages);
        let i = $index;
        track i
      ) {
        <atom-option [choiceValue]="i">
          {{ i + 1 }}
        </atom-option>
      }
    </atom-options>
  </atom-dropdown>
  <div class="number-text"><span i18n>of</span> {{ numberOfPages }}</div>

  <div
    class="icon-box cursor-pointer"
    [ngClass]="{ disabled: activePageIndex === numberOfPages - 1 }"
    (click)="onActivatePage(activePageIndex + 1)"
  >
    <atom-icon icon-id="atom:core:arrow_forward"></atom-icon>
  </div>
</div>
