import { Component, Input } from '@angular/core';
@Component({
  standalone: true,
  selector: 'ev-back-link',
  template: `
    <div class="back-link-container flex items-center cursor-pointer select-none">
      <i class="ai-back text-primary mr-2"></i>
      <a> {{ text }} </a>
    </div>
  `,
  styleUrls: ['./back-link.component.scss'],
})
export class BackLinkComponent {
  public backLinkText = $localize`Go back`;
  @Input() text = this.backLinkText;
}
