import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  input,
  OnDestroy,
  output,
  ViewChild,
} from '@angular/core';
import { DialogModule } from 'primeng/dialog';

@Component({
  standalone: true,
  imports: [CommonModule, DialogModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'ev-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
})
export class ModalDialogComponent implements OnDestroy {
  $title = input<string>();
  $subtitle = input<string>();
  $hideCloseButton = input(false);
  $showBrandLogo = input(false);
  $maxWidthClass = input<string>();
  closeDialog = output<void>();

  $showFooter = input(true);

  dialogOpen = true;

  @ViewChild('footerElement', { static: false }) footerContent?: ElementRef<HTMLElement>;

  ngOnDestroy() {
    this.dialogOpen = false;
  }

  // Check, that we really clicked just on the overlay.
  onOverlayClick(event: MouseEvent): void {
    if (this.$hideCloseButton()) {
      return;
    }

    const eventTarget = event.target as HTMLElement;
    if (eventTarget.classList.contains('overlay')) {
      this.onClose();
    }
  }

  public onClose(): void {
    this.dialogOpen = false;
    this.closeDialog.emit();
  }
}
